body {
  background: #ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Sections */
section {
	position: relative;
}

.section {
	padding: 40px 0;
	background: #fff;
}

.section-padded {
	padding: 140px 0 40px;
}

.artist-bg {
	background: rgb(240, 245, 255);
}

/*****/
/* navbar */


/* Navigation Bar ( Navbar ) */



/********/
/* Mint */
/********/

/*****/
/* Story */

.stories {
  background: url('blancvioletblanc.png');
}

.story {
	padding: 140px 0 40px;
}

.stripe {
	background: linear-gradient(0deg, rgb(184, 169, 255) 15%, #ccc3ff 50%, #ffffff 94%);
	background-repeat:no-repeat;
  }

/*****/
/* Artist */

.artist {
  background: url('mickael.png');
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 50vh;
}

/********/
/* Roadmap */

.timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem
}

@media (min-width:768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: "";
        display: block;
        border-top: .25rem dotted #3b82f6;
        width: 3.46rem;
        position: absolute;
        left: 7.5rem;
        top: .3125rem
    }
    .timeline-steps .timeline-step:not(:first-child):before {
        content: "";
        display: block;
        border-top: .25rem dotted #3b82f6;
        width: 3.8125rem;
        position: absolute;
        right: 7.5rem;
        top: .3125rem
    }
}

.timeline-steps .timeline-content {
    width: 10rem;
    text-align: center
}

.timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #3b82f6
}

.timeline-steps .timeline-content .inner-circle:before {
    content: "";
    background-color: #3b82f6;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    border-radius: 6.25rem;
    opacity: .5
}


/********/
/* Team */

.team {
	margin: 80px 0;
	padding-bottom: 60px;
	background: linear-gradient(0deg, rgb(184, 169, 255) 15%, #ccc3ff 50%, #ffffff 94%);
	box-shadow: 0 2px 3px rgba(192, 12, 12, 0.07);
}

.team-bg {
	background: linear-gradient(0deg, rgb(255, 255, 255) 15%, #ffffff 50%, #ddd8f5 94%);
}

.team .cover .overlay {
	height: 250px;
	padding-top: 60px;
	opacity: 0;
	background: linear-gradient(0deg, rgb(126, 100, 255) 15%, #9b89ff 50%, #ffffff 100%);
	-webkit-transition: opacity 0.45s ease;
	transition: opacity 0.45s ease;
}

.team:hover .cover .overlay {
	opacity: 1;
}

.team .avatar {
	position: relative;
	z-index: 2;
	margin-top: -60px;
	border-radius: 50%;
}

.team .title {
	margin: 50px 0;
}

/*******/
/* Contact
/*******/


.openbtn {
	margin-top: 0px;
	color: #ffffff;
	background: #945cee;

}

.btn.btn-submit {
	width: 100%;
	margin-top: 30px;
	color: #00a8ff;
	border: 2px solid #fff;
	background: #fff;
}

.btn.btn-submit:focus {
	font-weight: bold;
}

.btn.btn-submit:hover {
	color: #00a8ff;
	background: #fff;
}

/* Form Control */

.form-control {
	font-size: 18px;
	position: relative;
	left: 0;
	height: auto;
	padding: 20px 30px;
	border: 1px;
	border-radius: 0;
	box-shadow: 0;
}

.form-control.form-white {
	color: #fff;
	border: 2px solid white;
	background: transparent;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.form-control.form-white::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #fff;
}

.form-control.form-white:-moz-placeholder {
	opacity: 1;
	/* Mozilla Firefox 4 to 18 */
	color: #fff;
}

.form-control.form-white::-moz-placeholder {
	opacity: 1;
	/* Mozilla Firefox 19+ */
	color: #fff;
}

.form-control.form-white:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #fff;
}

.form-control.form-white:focus {
	background: rgba(255,255,255,0.2);
}

/* Popup */
.modal {
	padding: 0 25px !important;
}

.modal-dialog {
	width: 100%;
	max-width: 560px;
	margin: 0 auto;
}

.modal-popup {
	position: relative;
	padding: 45px 30px;
	text-align: center;
	background: url('popup2.jpg');
	box-shadow: none;
	border-radius: 2px;
}

.modal-popup a.close-link {
	font-size: 22px;
	position: absolute;
	top: 20px;
	right: 30px;
	color: #fff;
}

.popup-form {
	width: 90%;
	max-width: 375px;
	margin: 60px auto;
}

.popup-form .form-control {
	margin: 5px 0;
}

.popup-form .form-control.dropdown {
	text-align: left;
}

.popup-form .form-control.dropdown:after {
	content: '\f0d7';
	font-family: 'FontAwesome';
	display: inline-block;
	float: right;
	color: white;
}

.popup-form .dropdown .dropdown-menu {
	top: 65px;
	width: 100%;
	padding: 0;
	border: 2px solid white;
	border-top: 0;
	border-radius: 0;
	background: white;
	box-shadow: none;
}

.popup-form .dropdown .dropdown-menu li {
	font-size: 16px;
	width: 100%;
	background: transparent;
	color: #fff;
}

.popup-form .dropdown .dropdown-menu li a {
	width: 100%;
	padding: 15px 30px;
	color: #00a8ff;
}

.popup-form .dropdown .dropdown-menu li:hover a {
	color: #fff;
	background: #00a8ff;
}

/* Checkbox */

.checkbox-holder {
	white-space: nowrap;
}

.checkbox {
	position: relative;
	display: block;
}

.checkbox {
	position: relative;
}

.checkbox label:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	cursor: pointer;
	border: 2px solid white;
	background: transparent;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.checkbox input[type=checkbox]:focus + label:before {
	background: rgba(255,255,255,0.2);
}

.checkbox label:after {
	content: '';
	position: absolute;
	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	opacity: 0;
	background: white;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.checkbox input[type=checkbox] {
	opacity: 0;
	position: absolute;
	width: 0;
	height: 0;
}

.checkbox input[type=checkbox]:checked + label:after {
	opacity: 1;
}

.checkbox-holder span {
	position: relative;
	display: inline-block;
	margin: 0 0 0 10px;
	white-space: normal;
	color: #fff;
}



/* Footer */
footer {
	padding: 140px 0 40px;
	background: linear-gradient(0deg, rgb(184, 169, 255) 15%, #ccc3ff 50%, #ffffff 94%);
	background-repeat:no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
}


/******/
/* Colors */

.white {
	color: white;
}

.light {
	font-weight: 300;
}

.muted {
	color: #989da0;
}

/******/
/* autre */

li {
	list-style: none;
	color: #989da0;
  }

